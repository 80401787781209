/* You can add global styles to this file, and also import other style files */
//@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "primeicons/primeicons.css";

.mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
}

// .btn-warning {
//     background-color: rgb(255, 159, 64) !important;
//     border-color: rgb(255, 159, 64) !important;
// }

// .btn-warning:hover{
//     background-color: rgb(253, 131, 8) !important;
//     border-color: rgb(248, 124, 0) !important;
// }

input[type=text], textarea {
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    padding: 0.375rem 0.75rem;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: var(--bs-border-radius);
  }
   
  input[type=text]:focus, textarea:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    padding: 0.375rem 0.75rem;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgba(81, 203, 238, 1);
    border-radius: var(--bs-border-radius);
  }

  .ng-select .ng-select-container .ng-value-container .ng-input>input {
    background: none transparent;
    border: 1px;
    box-shadow: none;
    box-sizing: content-box;
    cursor: default;
    outline: auto;
    padding: 10, 10;
    width: 100%;
}

.ngb-dp-header {
  border-bottom: 0;
  border-radius: .25rem .25rem 0 0;
  padding-top: .25rem;
  background-color: var(--bs-primary) !important;
}

/* Removes the clear button from date inputs */
input[type="datetime-local"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
input[type="datetime-local"]::-webkit-inner-spin-button { 
  display: none;
}

/* Always display the drop down caret */
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="datetime-local"] {
  appearance: none;
  -webkit-appearance: none;
  color: #95a5a6;
  font-family: "Helvetica", arial, sans-serif;
  font-size: 18px;
  border:1px solid #ecf0f1;
  padding:5px;
  display: inline-block !important;
  visibility: visible !important;
}

input[type="datetime-local"], focus {
  color: #95a5a6;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-color: #3498db;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: #999
}
input[type="checkbox" i] {
  height: calc(1.5em + 0.75rem + 2px);
  vertical-align: middle;
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

table.table-bordered{
  border:1px dashed blue;
  margin-top:20px;
}
table.table-bordered > thead > tr > th{
  border:1px dashed blue;
}
table.table-bordered > tbody > tr > td{
  border:1px dashed blue;
}

// .modal{
//   display: block !important; /* I added this to see the modal, you don't need this */
// }

/* Important part */
// .modal-dialog{
//   overflow-y: initial !important
// }
// .modal-body{
//   max-height: 80vh;
//   overflow-y: auto;
// }

// .ct-bar:nth-child(1){
//   stroke: #379683 !important;
// }

// .ct-bar:nth-child(2){
//   stroke: #91A453 !important;
// }

// .ct-bar:nth-child(3){
//   stroke: #EFB200 !important;
// }